// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-brand-js": () => import("../src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-business-js": () => import("../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-house-js": () => import("../src/pages/lifeHouse.js" /* webpackChunkName: "component---src-pages-life-house-js" */),
  "component---src-pages-media-js": () => import("../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */)
}

